<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="父级文件夹"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择父级文件夹'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择父级文件夹"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>

        <a-form-item
          label="文件夹名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-input
            placeholder="请输入文件夹名称"
            v-decorator="['name', {rules: [{required: true, message: '请输入文件夹名称'}]}]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { listDirTree, editDir, addDir } from '@/api/system/source'
import pick from 'lodash.pick'

// 表单字段
const fields = [
  'parentId', 'name'
]

export default {
  name: 'SourceDirModal',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      // 当前类型 true编辑 false新增
      modalType: true,
      // 编辑id
      id: null,
      // 编辑key
      key: null,
      //  标题
      title: null
    }
  },
  methods: {
    /**
     * 修改
     */
    edit (record) {
      this.title = '修改文件夹'
      this.modalType = true
      this.id = record.id
      this.key = record.key
      this.handleDirTree()
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick(record, fields))
      })
    },
    /**
     * 添加
     */
    append (currentId) {
      if (currentId == null || currentId == 'undefined') {
        currentId = 0
      }
      this.title = '新建文件夹'
      this.modalType = false
      this.handleDirTree()
      this.visible = true
      this.$nextTick(()=> {
        this.form.setFieldsValue(pick({'parentId': currentId}, fields))
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.modalType) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 修改提交
     */
    editSubmit (values) {
      editDir(Object.assign(values, { id: this.id })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$notification.success({message: '修改成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    /**
     * 添加提交
     */
    addSubmit(values) {
      addDir(values).then((res) => {
        if (res.code === 200) {
          this.$notification.success({message: '新增成功'})
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.modalType = undefined
      this.id = undefined
      this.title = undefined
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDirTree () {
      this.loading = true
      listDirTree(0).then((res) => {
        if (res['code'] === 200) {
          if (this.modalType) {
            this.deleteThisDept(res.rows)
          }
          this.treeData = [{ name: '根目录', key: 0, parentId: 0, children: null }]
          this.treeData[0].children = res.rows
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 删除当前部门
     */
    deleteThisDept (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === this.key) {
          delete data[i]
        }
        if (data[i] && data[i].children) {
          this.deleteThisDept(data[i].children)
        }
      }
    }
  }
}
</script>
